var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("search")))]),_c('v-card-text',{on:{"drop":function($event){$event.preventDefault();return _vm.drop_json_file.apply(null, arguments)},"dragover":function($event){$event.preventDefault();}}},[_c('form',{attrs:{"autocapitalize":"off","autocorrect":"off","spellcheck":"false"}},[(_vm.expert)?_c('v-row',[_c('v-col',[_c('ParamInput',{ref:"search_code",staticClass:"mb-5 d-inline-block",style:({ width: '100%' }),attrs:{"arg":{ name: _vm.$t('query'), type: 'QUERY' }},on:{"submit":_vm.search},model:{value:(_vm.q),callback:function ($$v) {_vm.q=$$v},expression:"q"}})],1)],1):_c('v-row',[_c('v-col',[_c('v-text-field',{staticClass:"d-inline-block selector cond-width",attrs:{"dense":"","label":_vm.$t('query')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.search.apply(null, arguments)}},model:{value:(_vm.q),callback:function ($$v) {_vm.q=$$v},expression:"q"}}),_c('v-combobox',{staticClass:"d-inline-block ml-5 selector",style:({ width: '100px' }),attrs:{"label":_vm.$t('sort'),"dense":"","items":[
              { text: _vm.$t('default'), value: 'id' },
              { text: _vm.$t('pdate'), value: 'pdate' },
              { text: _vm.$t('pdate-rev'), value: '-pdate' },
              { text: _vm.$t('source'), value: 'source' },
              { text: _vm.$t('latest-imported'), value: '-id' },
              { text: _vm.$t('random'), value: 'random' },
            ]},model:{value:(_vm.sort),callback:function ($$v) {_vm.sort=$$v},expression:"sort"}})],1)],1),_c('v-row',{staticStyle:{"margin-top":"-24px"}},[_c('v-col',[_c('treeselect',{attrs:{"multiple":true,"options":_vm.filtering,"placeholder":_vm.$t('dataset'),"matchKeys":['label', 'tags']},scopedSlots:_vm._u([{key:"option-label",fn:function({ node }){return _c('label',{staticClass:"treeselect-option-label"},[_vm._v(" "+_vm._s(node.label)+" "),_vm._l((node.raw.tags.split(', ')),function(tag){return _c('span',{key:node.id + tag},[_vm._v(_vm._s(tag))])})],2)}}]),model:{value:(_vm.selected_datasets),callback:function ($$v) {_vm.selected_datasets=$$v},expression:"selected_datasets"}})],1)],1),_c('v-row',{staticClass:"ml-0 mb-3"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.search}},[_vm._v(_vm._s(_vm.$t("search")))]),_c('span',{staticClass:"ml-5",staticStyle:{"line-height":"100%","vertical-align":"middle"}},[_vm._v(" "+_vm._s(_vm.$t("grouping"))+" "),_c('v-combobox',{staticClass:"d-inline-block ml-1",staticStyle:{"width":"80px"},attrs:{"dense":"","flat":"","items":[
            { text: _vm.$t('none'), value: 'none' },
            { text: _vm.$t('group'), value: 'group' },
            { text: _vm.$t('source'), value: 'source' },
            { text: _vm.$t('author'), value: 'author' },
          ]},model:{value:(_vm.groups),callback:function ($$v) {_vm.groups=$$v},expression:"groups"}})],1),_c('span',{staticClass:"ml-5",staticStyle:{"line-height":"100%","vertical-align":"middle"}},[_c('v-checkbox',{staticClass:"d-inline-block ml-1",staticStyle:{"width":"80px"},attrs:{"dense":"","flat":"","label":_vm.$t('tag')},model:{value:(_vm.filter_by_tags),callback:function ($$v) {_vm.filter_by_tags=$$v},expression:"filter_by_tags"}})],1),_c('v-spacer'),_c('v-btn',{staticClass:"exports",on:{"click":_vm.export_query}},[_c('v-icon',[_vm._v("mdi-clipboard-outline")]),_vm._v(" "+_vm._s(_vm.$t("export-task"))+" ")],1),_c('v-btn',{staticClass:"exports",on:{"click":function($event){return _vm.export_file('xlsx')}}},[_c('v-icon',[_vm._v("mdi-file-excel")]),_vm._v(" "+_vm._s(_vm.$t("export-excel"))+" ")],1),_c('v-btn',{staticClass:"exports",on:{"click":function($event){return _vm.export_file('json')}}},[_c('v-icon',[_vm._v("mdi-download")]),_vm._v(" "+_vm._s(_vm.$t("export-json"))+" ")],1)],1)],1),_c('ResultsView',{ref:"results",staticClass:"mt-5",attrs:{"page_size":_vm.page_size,"load":_vm.external_json ?? _vm.load_search,"highlight_pattern":_vm.highlight_pattern}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }